export * from './api/api.appointment.service';
export * from './api/api.appointment-user-task.service';
export * from './api/api.base.service';
export * from './api/api.client.service';
export * from './api/api.client-communication';
export * from './api/api.client-document';
export * from './api/api.currency.service';
export * from './api/api.dashboard.service';
export * from './api/api.data.service';
export * from './api/api.dictionaries.service';
export * from './api/api.list-data.service';
export * from './api/api.organization.service';
export * from './api/api.package-service.service';
export * from './api/api.project-item.service';
export * from './api/api.project-item-organization.service';
export * from './api/api.project-organization.service';
export * from './api/api.project-verification.service';
export * from './api/api.service.service';
export * from './api/api.service-type.service';
export * from './api/api.task.service';
export * from './api/api.token.service';
export * from './api/api.user.service';
export * from './auth-guard.service';
export * from './compare.service';
export * from './disable-expanding.service';
export * from './error.handler';
export * from './group-by.service';
export * from './input.service';
export * from './lang.storage.service';
export * from './mask.service';
export * from './options.service';
export * from './role.storage.service';
export * from './token.storage.service';
export * from './user-credentials.storage.service';
export * from './api/api.appointment-patch.service';
export * from './api/api.client-register.service';
export * from './api/api.organization-by-role-lookup.service';
export * from './api/api.package-service-by-project-id.service';
export * from './api/api.package.delete.service';
export * from './api/api.report.service';
export * from './api/api.service-types-delete-multiple.service';
export * from './api/api.service-delete-multiple.service';
export * from './api/api.package-service-by-package-id.service';
export * from './api/api.package.service';
export * from './api/api.user-by-role-and-org.service';
export * from './scroll-top.service';
export * from './api/api.task-distr-task-by-service.service';
export * from './api/api.client-card.service';
export * from './api/api.client-filter-lookup.service';
export * from './api/api.task-distr-task-by-package.service';
export * from './api/api.distr-task-by-project.service';
export * from './format-payload.service';
export * from './get-safe.service';
export * from './api/api.project-item-organization-by-project-id.service';
export * from './api/api.projeciItem-by-projectid.service';
export * from './api/api.task-MassDelete.service';
export * from './api/api.partner.service';
export * from './api/api.partner-communication.service';
export * from './api/api.PartnerContactAttempt .service';
export * from './api/api.PartnerScreening.service';
export * from './api/api.dictionaries-by-name.service';
export * from './api/api.client-clientPartner.service';
export * from './api/api.myclients-lookup';
export * from './api/api.organization-by-coordinate';
export * from './api/api.task-task-by-consultant';
export * from './api/api.appointment-createby-web';
export * from './api/api.client-light.service';
export * from './api/api.client-light-client.service';
export * from './api/api.project.service';
export * from './api/api.regions-autocomplete.service';
export * from './api/api.user.service';
export * from './api/api.violation.service';
export * from './api/api.appeal.service';
export * from './api/api.appointment-serviceedit';

export interface DateInputFields {
    lookup: string;
    dateSingle: string;
    dateHiddenSingle: string;
    dateFrom: string;
    dateFromHidden?: string;
    dateSinglePlaceHolder: string;
    dateTo: string;
    dateToHidden?: string;
    dateFromPlaceholder: string;
    dateToPlaceholder: string;
}

export const sharedPayload = {
    pageSize: 50,
    orderBy: 'name',
    reverse: false,
};

export const page = 1;
export const optionSelected = 25;
