import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { ProjectRoleEnum, UserRoleEnum } from 'core/enums';
import { fromEvent } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { debounceTimeDelay } from 'core/shared-const';

@Injectable({
    providedIn: 'root',
})
export class InputService {
    patternForEng = '(?:[^А-Яа-я]*)';
    patterNotMinus = '[0-9.](?:[^-]*)';
    patternOnlyNumbers = '^[0-9.,]+$';
    patternOnlyNumbersStrict = '^[0-9]+$';
    patternOnlyNumbersStrictWithoutZero = '^[1-9]+$';
    patternOnlyNumbersStrictWithoutFirstZero = '^[1-9][0-9]*$';
    patternOnlyNumbersWithOutAnyChar = '^[0-9]+$';
    patternPassword = '^[0-9a-zA-Z]+$';
    patternForCod = "^[0-9a-zA-ZА-ЯҐЄІЇЎа-яґєіїў'-;?]+$";
    patterNotMinusAndNoDotes = '^[0-9](?:[^-.,]*)';
    patternUkrainian = '^[А-ЯҐЄІЇЎа-яґєіїў]+$';
    patternNotRussian = '^[^(ЫЪЭыъэ]+$';
    patternOnlyTwoLetters = '^[0-9.]+$';
    phoneNumber = /^\+380|0[0-9]{7}/i;

    public ValidateTwoChar(control) {
        if (control.value) {
            const controlArray = control.value.toString().split('');
            for (let i = 0; i < controlArray.length; i++) {
                if (controlArray[i] === ',' || controlArray[i] === '.') {
                    const arrays = controlArray.slice(i);
                    if (arrays.length > 3) {
                        return { ValidateTwoChar: true };
                    } else {
                        return null;
                    }
                }
            }
        }
    }

    noWhitespaceValidator(control) {
        if (control.value) {
            if (control.value.replace(/ /g, '') !== control.value) {
                return { whitespace: true };
            }
        }
    }

    public noWhitespaceValidatorOnlyAtStart(control) {
        if (control.value) {
            const a = control.value.split('');
            for (let i = 0; i < a.length; i++) {
                if (a[i] === ' ') {
                    const controlSliced = a.slice(0, i).join('').replace(/ /g, '');
                    if (controlSliced === '') {
                        return { whitespace: true };
                    } else {
                        return null;
                    }
                }
            }
        }
    }

    public ValidateDate(control) {
        try {
            if (control.errors.errors.matDatepickerParse.text) {
                if (control.valid) {
                    return null;
                } else {
                    return { ValidateDate: true };
                }
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    ValidateDateFrom2010(control) {
        try {
            let date = control.value;
            if (date.length === 0) {
                return null;
            }
            date = date.split('.').join('');
            const d = date.slice(0, 2);
            const mm = date.slice(2, 4);
            const yyyy = date.slice(4, 8);
            const newDate = new Date();
            const inputDate = new Date(`${mm}.${d}.${yyyy}`);
            if (newDate.getTime() <= inputDate.getTime()) {
                return { ValidateDate: true, FromNow: true };
            }
            if (d > 31) {
                return { ValidateDate: true };
            }
            if (mm > 12) {
                return { ValidateDate: true };
            }
            if (yyyy < 2010) {
                return { ValidateDate: true };
            }
        } catch (e) {}
    }

    public ValidateDateCustom(control) {
        try {
            let date = control.value;
            if (date.length === 0) {
                return null;
            }
            date = date.split('.').join('');
            const d = date.slice(0, 2);
            const mm = date.slice(2, 4);
            const yyyy = date.slice(4, 8);
            if (d > 31) {
                return { ValidateDate: true };
            }
            if (mm > 12) {
                return { ValidateDate: true };
            }
            if (yyyy < 1900 || yyyy > 2100) {
                return { ValidateDate: true };
            }
        } catch (e) {}
    }

    ValidateDateTillNowDayPlus100Y(control) {
        try {
            let date = control.value;
            if (date.length === 0) {
                return null;
            }
            date = date.split('.').join('');
            const d = date.slice(0, 2);
            const mm = date.slice(2, 4);
            const yyyy = date.slice(4, 8);
            const nowYear = new Date().getFullYear();
            const nowMonth = new Date().getMonth();
            const nowDate = new Date().getDate();
            const maxDate = new Date(nowYear + 100, nowMonth, nowDate);
            const maxYear = maxDate.getFullYear();
            if (yyyy >= maxYear) {
                return { ValidateDate: true };
            }
            if (d > 31) {
                return { ValidateDate: true };
            }
            if (mm > 12) {
                return { ValidateDate: true };
            }
            if (yyyy < 2010) {
                return { ValidateDate: true };
            }
        } catch (e) {}
    }

    ValidateDateCustomFromNow(control) {
        try {
            let date = control.value;
            if (date.length === 0) {
                return null;
            }
            date = date.split('.').join('');
            const d = date.slice(0, 2);
            const mm = date.slice(2, 4);
            const yyyy = date.slice(4, 8);
            const newDate = new Date();
            const inputDate = new Date(`${mm}.${d}.${yyyy}`);
            if (inputDate.getTime() > newDate.getTime()) {
                return { ValidateDate: true, FromNow: true };
            }
            if (d > 31) {
                return { ValidateDate: true };
            }
            if (mm > 12) {
                return { ValidateDate: true };
            }
            if (yyyy < 1900) {
                return { ValidateDate: true };
            }
        } catch (e) {}
    }

    public onlyTwoLettersAfterDotes(control) {
        if (control.value) {
            const parts = `${control.value}`.split('.');
            const numberOfDotes = parts.length - 1;
            if (numberOfDotes > 1) {
                return { invalid: true };
            }
            if (parts[parts.length - 1].length > 2) {
                return { invalid: true };
            }
        }
    }

    public timeValidation(control) {
        if (control.value) {
            if (control.value.length !== 4) {
                return { invalid: true };
            }
            const h = control.value.slice(0, 2);
            const m = control.value.slice(2, 4);
            if (!m) {
                return { invalid: true };
            }
            if (h > 23) {
                return { invalid: true };
            }
            if (m > 59) {
                return { invalid: true };
            }
            if (isNaN(h)) {
                return { invalid: true };
            }
            if (isNaN(m)) {
                return { invalid: true };
            }
        } else {
            return null;
        }
    }

    public notInListValidator(control) {
        if (control.value) {
            return { notInTheList: true };
        }
    }

    public validateOrganization(roles, organizations) {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (roles.includes(ProjectRoleEnum.ServiceDeliveryPoint)) {
                if (!control.touched) {
                    return null;
                }
                const elem = organizations.some(el => el?.name === control.value);
                if (!elem) {
                    return { notInList: true };
                } else {
                    return null;
                }
            } else {
                return null;
            }
        };
    }

    public validateUsers(role) {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (Number(role) === UserRoleEnum.Client) {
                return null;
            } else {
                if (!control.touched) {
                    return null;
                }

                return [Validators.required];
            }
        };
    }

    returnDate(date: string): string {
        const d = date.slice(0, 2);
        const mm = date.slice(2, 4);
        const y = date.slice(4, 8);
        const returnDate: Date = new Date(`${mm}.${d}.${y}`);
        returnDate.setUTCHours(0, 0, 0, 0);
        returnDate.setDate(returnDate.getDate() + 1);

        return this.formatDateToString(returnDate);
    }

    formatDateToString(d: Date | string): string {
        console.log(d);
        let targetDate = null;
        if (d instanceof Date) {
            targetDate = d;
        } else {
            targetDate = new Date(d);
        }
        if (targetDate) {
            const month = targetDate.getMonth() + 1;
            const monthString = this.getDateAndTimeData(month);
            const dateNum = targetDate.getDate();
            const dateNumString = this.getDateAndTimeData(dateNum);
            const h = targetDate.getHours();
            const hS = this.getDateAndTimeData(h);
            const min = targetDate.getMinutes();
            const minString = this.getDateAndTimeData(min);
            const sec = targetDate.getSeconds();
            const secString = this.getDateAndTimeData(sec);

            const date = [targetDate.getFullYear(), monthString, dateNumString].join('-');
            const time = [hS, minString, secString].join(':');

            return date + 'T' + time;
        }

        return d as string;
    }

    getDateAndTimeData(num: number) {
        let str = num.toString();
        if (str.length === 1) {
            str = '0' + str;
        }

        return str;
    }

    getDatePayload(dateSingle: any, dateFrom: any, dateTo: any, lookup: any): any {
        let dateStart;
        let dateEnd;
        if (dateSingle || dateFrom || dateTo) {
            if (lookup === '=') {
                dateEnd = this.returnDate(dateSingle);
                dateStart = this.returnDate(dateSingle);
            }
            if (lookup === '>=') {
                dateStart = this.returnDate(dateSingle);
            }
            if (lookup === '<=') {
                dateEnd = this.returnDate(dateSingle);
            }
            if (lookup === 'між') {
                dateStart = this.returnDate(dateFrom.format('DDMMYYYY'));
                dateEnd = this.returnDate(dateTo.format('DDMMYYYY'));
            }
        }

        return [dateStart, dateEnd];
    }
}

export function autocompleteScroll(array, el, page, method, totalCount, pagesCounter, pageSize) {
    setTimeout(() => {
        if (this[el] == undefined ) return;
        fromEvent(this[el].panel.nativeElement, 'scroll')
            .pipe(
                map(x => this[el].panel.nativeElement.scrollTop),
                takeUntil(this.autocompleteTrigger.panelClosingActions),
            )
            .subscribe(x => {
                const scrollTop = this[el].panel.nativeElement.scrollTop;
                const scrollHeight = this[el].panel.nativeElement.scrollHeight;
                const elementHeight = this[el].panel.nativeElement.clientHeight;
                if (scrollHeight === scrollTop + elementHeight) {
                    if (totalCount[page] <= pagesCounter[page] * pageSize) {
                        return;
                    }
                    ++pagesCounter[page];
                    setTimeout(() => method.bind(this)());
                }
            });
    });
}

export function __isInlistValidator(data, field, required?) {
    if (!required) {
        if (!this.form?.get(field)?.value?.length) {
            this.form?.get(field)?.clearValidators();

            return;
        }
    }
    const element = data.find(e => e.name === this.form?.get(field)?.value);
    if (!element) {
        this.form?.controls[field]?.setErrors({ notInList: true });
    } else {
        this.form?.get(field)?.clearValidators();
    }
}

export async function getIdByName(data, name) {
    if (!name || !data) return null;
    // console.log(await data.find(e => e.name === name));

    return await data.find(e => e.name === name);
}

export function valueSelected(myArray) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
        const selectedValue = c.value;
        if (!selectedValue) {
            return null;
        }
        const pickedOrNot = (myArray as any)?.filter((alias: any) => alias.name === selectedValue);
        if (pickedOrNot?.length > 0) {
            return null;
        } else {
            return { match: true };
        }
    };
}

export function validatorsForNames() {
    const patternName = "^([А-ЯҐЄІЇЎа-яґєіїў '-]*|[?]*)";
    const patternNotRussian = '^[^(ЫЪЭыъэ]+$';

    function noWhitespaceValidator(control) {
        if (control.value) {
            if (control.value.replace(/ /g, '') !== control.value) {
                return { whitespace: true };
            }
        }
    }

    return Validators.compose([
        Validators.pattern(patternName),
        Validators.required,
        noWhitespaceValidator,
        Validators.pattern(patternNotRussian),
    ]);
}

export function validatorsForLastNames() {
    const patternLastName = "^([А-ЯҐЄІЇЎа-яґєіїў '-]*|[?]{3})";
    const patternNotRussian = '^[^(ЫЪЭыъэ]+$';

    function noWhitespaceValidator(control) {
        if (control.value) {
            if (control.value.replace(/ /g, '') !== control.value) {
                return { whitespace: true };
            }
        }
    }

    return Validators.compose([
        Validators.pattern(patternLastName),
        Validators.required,
        noWhitespaceValidator,
        Validators.pattern(patternNotRussian),
    ]);
}

export function phoneValidator() {
    const phoneNumber = /^\+380|0[0-9]{7}/i;

    return Validators.compose([Validators.pattern(phoneNumber), Validators.required]);
}

export function emailValidator() {
    const email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}/i;

    return Validators.compose([Validators.pattern(email), Validators.required]);
}

export function updatePhoneValue(form: any, field: string) {
    form?.get(field)
        ?.valueChanges.pipe(debounceTime(debounceTimeDelay))
        .subscribe(val => {
            if (val) {
                if (val.startsWith('+38') || val.startsWith('38')) {
                    let newValue = val.replace(/\D/g, '');
                    newValue = newValue.replace('38', '');
                    console.log(newValue);
                    form.get(field).patchValue(newValue);
                }
            }
        });
}
