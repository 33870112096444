import {AbstractModel} from './abstract.model';

export interface AppointmentModel extends AbstractModel {
  client: {
    code: string;
    name: string;
    id: number
  };
  number: number;
  date: string;
  latitude: number;
  longitude: number;
  state: number;
  startTime: number;
  endTime: number;
  verificationType: number;
  userName: string;
  deliveryPoint: {
    edrpou: string;
    chief: string;
    country: {
      code: string;
      name: string;
      id: number
    };
    region: {
      code: string;
      name: string;
      id: number
    };
    district: {
      code: string;
      name: string;
      id: number
    };
    settlement: {
      code: string;
      name: string;
      id: number
    };
    streetType: {
      code: string;
      name: string;
      id: number
    };
    street: {
      code: string;
      name: string;
      id: number
    };
    houseNum: string;
    housing: string;
    flatNum: string;
    latitude: number;
    longitude: number;
    roles: [
      number
      ];
    orgRoles: [
      {
        organizationId: number;
        role: number;
        id: number
      }
      ];
    code: string;
    name: string;
    nameEn: string;
    id: number
  };
  verificationNotRequired: true;
  appointmentTasks: [
    {
      serviceId: number;
      serviceName: string;
      count: number;
      packageName: string;
      resultType: number;
      resultDictionary: number;
      id: number
    }
    ];
  id: number;
}
